import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Menu from "./../components/menu";

const Header = ({ siteTitle }) => (
  <header>
    <div class="header-area ">
        <div class="header-top_area d-none d-lg-block">
            <div class="container">
                <div class="row">
                    <div class="col-xl-5 col-md-5 ">
                        <div class="header_left">
                            <p>Welcome to Cloud Up Realty</p>
                        </div>
                    </div>
                    <div class="col-xl-7 col-md-7">
                        <div class="header_right d-flex">
                                <div class="short_contact_list">
                                        <ul>
                                            <li><a href="#"> <i class="fa fa-envelope"></i> help@clouduprealty.com</a></li>
                                            <li><a href="#"> <i class="fa fa-phone"></i> 312-593-2173</a></li>
                                        </ul>
                                    </div>
                                    {/* <div class="social_media_links">
                                        <a href="#">
                                            <i class="fa fa-linkedin"></i>
                                        </a>
                                        <a href="#">
                                            <i class="fa fa-facebook"></i>
                                        </a>
                                        <a href="#">
                                            <i class="fa fa-google-plus"></i>
                                        </a>
                                    </div> */}
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <Menu></Menu>

    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
