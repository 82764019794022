import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { updateMenu } from "../state/app";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuOpen: false
    }
  }

  updateMenu(menuId) {
    console.log('update menu', menuId);
    this.props.updateMenuAction(menuId);
  }

  toggleMobileMenu() {
    this.setState({
      mobileMenuOpen: !this.state.mobileMenuOpen
    })
  }

  render() {

    console.log('menu state:', this.props.activeMenu);
    return (
    <div id="sticky-header" class="main-header-area">
      <div class="container">
          <div class="header_bottom_border">
              <div class="row align-items-center">
                  <div class="col-xl-3 col-lg-2">
                      <a href="/"><div class="logo logo-img"></div></a>
                  </div>
                  <div class="col-xl-6 col-lg-7">
                      <div class="main-menu  d-none d-lg-block">
                          <nav>
                              <ul id="navigation">
                                  <li onClick={() => { this.updateMenu('home'); }}>
                                    <Link className={this.props.activeMenu === 'home'? 'active' : ''} to="/">Home</Link>
                                  </li>
                                  <li onClick={() => { this.updateMenu('about'); }}>
                                    <Link className={this.props.activeMenu === 'about'? 'active' : ''} to="/about/">About Us</Link>
                                  </li>
                                  {/* <li onClick={() => { this.updateMenu('property'); }}>
                                    <Link className={this.props.activeMenu === 'property'? 'active' : ''} to="/property/">Property</Link>
                                  </li> */}
                                  <li onClick={() => { this.updateMenu('buyers'); }}>
                                    <Link className={this.props.activeMenu === 'buyers'? 'active' : ''} to="/buyers/">Buyers</Link>
                                  </li>
                                  <li onClick={() => { this.updateMenu('sellers'); }}>
                                    <Link className={this.props.activeMenu === 'sellers'? 'active' : ''} to="/sellers/">Sellers</Link>
                                  </li>
                                  <li onClick={() => { this.updateMenu('contact'); }}>
                                    <Link className={this.props.activeMenu === 'contact'? 'active' : ''} to="/contact/">Contact Us</Link>
                                  </li>
                              </ul>
                          </nav>
                      </div>
                  </div>
                  {/* <div class="col-xl-3 col-lg-3 d-none d-lg-block">
                      <div class="Appointment">
                          <div class="search_btn">
                              <a href="#">
                                  <i class="ti-search"></i>
                              </a>
                          </div>
                          <div class="book_btn d-none d-lg-block">
                            <Link onClick={() => { this.updateMenu('contact'); }} className={this.props.activeMenu === 'contact'? 'active' : ''} to="/contact/">Some button</Link>
                          </div>
                      </div>
                  </div> */}
                  <div class="col-12">
                  <div class="mobile_menu d-block d-lg-none">
                    <div class="slicknav_menu">
                      <a onClick={() => { this.toggleMobileMenu(); }} href="#" aria-haspopup="true" role="button" tabindex="0" class="slicknav_btn" >
                      <span class="slicknav_menutxt">MENU</span>
                        <span class="slicknav_icon">
                        <span class="slicknav_icon-bar"></span>
                        <span class="slicknav_icon-bar"></span>
                        <span class="slicknav_icon-bar"></span>
                      </span>
                      </a>
                      {this.state.mobileMenuOpen && <ul class="slicknav_nav slicknav_hidden" aria-hidden="true" role="menu" >
                        <li onClick={() => { this.updateMenu('home'); }}>
                            <Link className={this.props.activeMenu === 'home'? 'active' : ''} to="/">Home</Link>
                          </li>
                          <li onClick={() => { this.updateMenu('about'); }}>
                            <Link className={this.props.activeMenu === 'about'? 'active' : ''} to="/about/">About Us</Link>
                          </li>
                          {/* <li onClick={() => { this.updateMenu('property'); }}>
                            <Link className={this.props.activeMenu === 'property'? 'active' : ''} to="/property/">Property</Link>
                          </li> */}
                          <li onClick={() => { this.updateMenu('buyers'); }}>
                            <Link className={this.props.activeMenu === 'buyers'? 'active' : ''} to="/buyers/">Buyers</Link>
                          </li>
                          <li onClick={() => { this.updateMenu('sellers'); }}>
                            <Link className={this.props.activeMenu === 'sellers'? 'active' : ''} to="/sellers/">Sellers</Link>
                          </li>
                          <li onClick={() => { this.updateMenu('contact'); }}>
                            <Link className={this.props.activeMenu === 'contact'? 'active' : ''} to="/contact/">Contact Us</Link>
                          </li>
                        </ul>}   
                      </div>
                    </div>




                  </div>
              </div>
          </div>

      </div>
  </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    activeMenu: state.app.activeMenu
  }
}

const mapDispatchToProps = dispatch => ({
  updateMenuAction: (menuId) => {
    console.log('test dispatch menu', menuId)
    dispatch(updateMenu(menuId))
  }
  
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Menu);
