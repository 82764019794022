import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Bradcam from "../components/bradcam"

class Subscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactName: '',
      contactEmail: '',
      contactTitle: '',
      contactMsg: '',
      sendStatus: ''
    }
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    console.log(this.state.contactName, this.state.contactEmail, this.state.contactTitle, this.state.contactMsg)

    const request = new Request('https://ffa0h5g5r2.execute-api.us-east-1.amazonaws.com/mailService/sendmail', 
      {
        method: 'POST', 
        headers: new Headers({'content-type': 'application/json'}),
        mode: 'cors',
        body: JSON.stringify({
          "name": this.state.contactName,
          "email": this.state.contactEmail,
          "title": this.state.contactTitle,
          "msg": this.state.contactMsg,
          "subscribe": true
        }),
      
      }
    );
 
    fetch(request)
      .then(response => {
        if (response.status === 200) {
          this.setState({
            contactName: '',
            contactEmail: '',
            contactTitle: '',
            contactMsg: '',
            sendStatus: 'Subscription Successful'
          });
          return response.json();
        } else {
          throw new Error('Something went wrong on api server!');
        }
      })
      .then(response => {
        console.debug(response);
        // ...
      }).catch(error => {
        console.error(error);
      });
  }



  updateEmailValue(evt) {
    this.setState({
      contactEmail: evt.target.value
    });
  }


  render() {
    return (
      <div class="newsletter_form">
        <input value={this.state.contactEmail} onChange={evt => this.updateEmailValue(evt)} type="text" placeholder="Enter your mail" />
        <button onClick={this.handleClick}>Subscribe</button>
        <div class="subscription-status">{this.state.sendStatus}</div>
    </div>
    )
  }
}
  


export default Subscribe;
