import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Bradcam = ({ siteTitle }) => (
  <div class="bradcam_area bradcam_bg_1">
      <div class="container">
          <div class="row">
              <div class="col-xl-12">
                  <div class="bradcam_text text-center">
                      <div class="slider_text text-center justify-content-center">
                            <h3>云尚地产</h3>
                            <p>住宅买卖，商业地产，全美置业，房屋托管​</p>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
)

Bradcam.propTypes = {
  siteTitle: PropTypes.string,
}

Bradcam.defaultProps = {
  siteTitle: ``,
}

export default Bradcam
